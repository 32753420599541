.slidingLabel {
    display: inline-block;
    position: relative;
    margin-top: 2em;
    min-width: 158px;
}

.slidingLabel input,
.slidingLabel select {
    border-width: 0;
    border-bottom-width: 2px;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    transition: border linear 200ms;
    background: none;
}

.slidingLabel input ~ label,
.slidingLabel select ~ label {
    cursor: pointer;
    position: absolute;
    top: 0.4em;
    margin: 0;
    transition: all ease 200ms;
    color: #918E8C;
}

.slidingLabel input:focus,
.slidingLabel select:focus {
    box-shadow: none;
}

.slidingLabel select ~ label,
.slidingLabel input:focus ~ label,
.slidingLabel input.filled ~ label {
    cursor: initial;
    top: -1.4em;
}

/* import fonts */
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts/';

@font-face {
    font-family: 'FontFamily';
    src: url(../Fonts/SourceSansPro-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'FontFamily';
    src: url(../Fonts/SourceSansPro-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'FontFamily';
    src: url(../Fonts/SourceSansPro-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'FontFamily';
    src: url(../Fonts/SourceSansPro-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'FontFamily-Headline';
    src: url(../Fonts/MMHeadlinePro-Regular.woff) format('woff'),
    url(../Fonts/MMHeadlinePro-Regular.woff2) format('woff2');
}

/* set settings for SCSS file */

$font-family-sans-serif-headline: FontFamily-Headline, FontFamily, Arial, sans-serif;
$font-family-sans-serif: FontFamily, Arial, sans-serif;

$primary-button-color: #000000;
$primary-disabled-button-color: #918E8C;
$primary-button-hover-color: #494745;
$primary-button-font-color: #ffffff;

$primary: #E00A17;
$secondary: #666666;
$dark: #000000;

$md-checkbox-size: 1.25em;
$md-checkbox-padding: .25em;
$md-checkbox-border-radius: 0.125em;

$grid-gutter-width: 20px;

/* import other styles */

@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/brands';
@import '@fortawesome/fontawesome-pro/scss/solid';
@import '~jquery-ui/themes/base/all.css';
@import '~bootstrap/scss/bootstrap';
@import 'slidingLabel';
@import 'price';

/* override slidingLabel.scss */

.slidingLabel {
    display: inline-block;
    min-width: 158px;
}

.slidingLabel select,
.slidingLabel input {
    border-bottom-width: 2px;
    background: none;
}

.slidingLabel select ~ label,
.slidingLabel input ~ label {
    color: #918E8C;
}

/* override bootstrap defaults */

.form-control:focus {
    border-color: #918E8C;
}

.popover {
    box-shadow: 0 0 10px #0000001A;
    border: none;
    border-radius: 4px;
}

.loadingIndicator {
    background-color: rgba(204, 204, 204, 0.5);
    z-index: 1;
    top: 0;
    left: 0;

    .iw-spinner {
        left: 50%;
        top: 50%;
    }
}

.col-11 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.carousel-caption.iw-walletCarouselCaption {
    right: 0;
    bottom: 0;
    left: 0;
}

.carousel-control-next.iw-dark,
.carousel-control-prev.iw-dark,
.carousel-indicators.iw-dark {
    filter: invert(100%);
}

[data-toggle='collapse'].iw-collapseIcon {
    font-weight: bold;
}
[data-toggle='collapse'].iw-collapseIcon:after {
    display: inline-block;
    font: normal normal normal 14px/1 'Font Awesome\ 5 Pro';
    font-weight: bold;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f054';
    transform: rotate(270deg);
    transition: all linear 0.25s;
    float: right;
}
[data-toggle='collapse'].iw-collapseIcon.collapsed {
    font-weight: normal;
}
[data-toggle='collapse'].iw-collapseIcon.collapsed:after {
    transform: rotate(90deg);
    font-weight: normal;
}

/* own styles */

html,
body {
    min-width: 320px;
}

a {
    color: black;
}

.iw-categoryDropdown {
    border: 2px solid #ffffff;
    padding: 10px 24px;
    border-radius: 8px;
}

.iw-boxShadow-none {
    box-shadow: none !important;
}

.iw-bgGrey {
    background-color: rgb(243, 243, 243);
}

.iw-cursorPointer {
    cursor: pointer;
}

.iw-exceptionContainer {
    min-height: 60vh;
}

.iw-word-break {
    word-break: break-all;
}

.iw-logoContainer {
    img {
        max-width: 250px;
    }
}

.iw-disabled {
    .iw-primaryButtonText {
        background-color: $primary-disabled-button-color !important;
        cursor: not-allowed !important;
    }

    .iw-primaryButtonSymbol {
        background-color: $primary-disabled-button-color !important;
        cursor: not-allowed !important;
    }
}

.iw-red-color {
    color: #E30A17;
}

.iw-md-checkbox {
    position: relative;
    margin: 1em 0;
    text-align: left;

    .iw-termsAndConditions {
        font-size: 14px;
        max-width: 725px;

        a {
            border-bottom-color: #E30A17;
        }
    }

    .iw-termsAndConditions label {
        line-height: 1.8;
        vertical-align: top;
        clear: both;
        padding-left: 1px;

        &:before, &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 1%;
            margin-top: 5px;
        }

        &:before {
            width: $md-checkbox-size;
            height: $md-checkbox-size;
            background: #fff;
            border: 2px solid #AAAAAA;
            border-radius: $md-checkbox-border-radius;
            transition: background .3s;
        }
    }

    input[type='checkbox'] {
        outline: 0;
        visibility: hidden;
        width: $md-checkbox-size;
        display: block;
        float: left;
        font-size: inherit;
        margin: 0 10px 0 0;

        &:checked {
            + .iw-termsAndConditions label:before{
                background: $primary;
                border:none;
            }
            + .iw-termsAndConditions label:after {
                $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;
                transform: translate($md-checkbox-padding, ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)) rotate(-45deg);
                width: $md-checkmark-size;
                height: $md-checkmark-size / 2;
                border: 0.125em solid #fff;
                border-top-style: none;
                border-right-style: none;
            }
        }
    }
}

.iw-primaryButton,
.iw-primaryButton .btn {
    color: $primary-button-font-color !important;
}

.iw-primaryButton {
    font-size: 14px;

    .iw-primaryButtonText {
        background-color: $primary-button-color;
        border: none;
        border-radius: 3px 0 0 3px;
    }

    .iw-primaryButtonSymbol {
        background-color: $primary-button-color;
        border: none;
        border-radius: 0 3px 3px 0;
        margin-left: -1.5px;
    }

    &:hover {
        .iw-primaryButtonText {
            background-color: $primary-button-hover-color;
        }

        .iw-primaryButtonSymbol {
            background-color: $primary-button-hover-color;

            svg {
                animation: slideArrowsIn 0.6s;
            }
        }
    }
}

@keyframes slideArrowsIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.iw-secondaryButton {
    height: 39px;
    border: 1px solid #E6E8E9;
    border-radius: 2px;
    padding: 8px;
    font-size: 14px;

    &:hover {
        background-color: #eeeeee;
    }
}

.f3-form-error {
    border-bottom: 1px solid #ff0000;
}

.iw-greenColor {
    color: #29A954;
}

.form-validation-message {
    color: #ff0000;
}

.iw-box-shadow {
    filter: drop-shadow(0 0 5px rgba(0,0,0,.15));
}

#header {
    background-color: #df0000ff;
    color: #ffffff;

    .iw-headerCategoryContainer {
        a {
            &:hover {
                color: #333333;
            }
        }
    }

    .iw-basketButton {
        width: 42px;
        height: 42px;

        .badge {
            top: -10px;
            right: -10px;
            width: 20px;
            height: 20px;
            padding: 3px 0 0;
            font-size: 12px;
        }
    }

    .iw-navigationButton.btn:focus,
    .iw-navigationButton.btn.focus {
        box-shadow: none;
    }
}

#content {
    .iw-categories {
        .iw-categoryContainer {
            width: 250px;
            height: 250px;

            span {
                bottom: 25px;
                left: 25px;
                z-index: 1;
            }
        }

        .iw-categoryContainerShadow {
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            bottom: -100%;
            right: 0;
            transition: transform .3s cubic-bezier(.215, .61, .355, 1);
            pointer-events: none;
            z-index: 0;
        }

        .iw-categoryContainerShadow:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 50%;
            height: 35%;
            background-image: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, .004) 8.1%, rgba(0, 0, 0, .017) 15.5%, rgba(0, 0, 0, .036) 22.5%, rgba(0, 0, 0, .061) 29%, rgba(0, 0, 0, .091) 35.3%, rgba(0, 0, 0, .123) 41.2%, rgba(0, 0, 0, .158) 47.1%, rgba(0, 0, 0, .192) 52.9%, rgba(0, 0, 0, .227) 58.8%, rgba(0, 0, 0, .259) 64.7%, rgba(0, 0, 0, .289) 71%, rgba(0, 0, 0, .314) 77.5%, rgba(0, 0, 0, .333) 84.5%, rgba(0, 0, 0, .346) 91.9%, rgba(0, 0, 0, .35));
        }

        .iw-categoryContainerShadow:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 50%;
            top: 50%;
            background-color: rgba(0, 0, 0, .35);
        }

        &:hover {
            .iw-categoryContainerShadow {
                transform: translateY(-50%);
            }
        }

        &:nth-child(3n+3) {
            margin-right: 0;
        }
    }

    .iw-productPage {
        .iw-imageContainer {
            width: 50%;

            .iw-productImage {
                border-radius: 18px;
            }
        }

        .iw-productInformationContainer {
            width: 50%;

            .iw-productImage {
                max-height: 279px;
            }

            .iw-productSpecial {
                div {
                    margin: 10px;
                }

                span {
                    margin-left: 10px;
                }
            }
        }
    }

    .iw-fontSelectionContainer {
        width: 50%;
    }

    .iw-showCategory {
        .iw-categoryName {
            font-size: 30px;
            padding-bottom: 10px;
        }

        .iw-subCategoryName {
            font-size: 25px;
            padding-bottom: 10px;
        }

        .iw-productListContainer {
            .iw-productContainer {
                width: 300px;
                border: 1px solid #DDDDDD;
                margin: 0 1rem 1rem 0;

                .iw-contentContainer {

                    .iw-productImage {
                        display: flex;
                        justify-content: center;

                        img {
                            max-height: 215px;
                            width: auto;
                        }
                    }
                }

                a:hover {
                    color: #333333;
                }
                .iw-product-but-mixed {
                    cursor: not-allowed;
                }
            }



            &:hover {
                color: initial;
            }
        }
    }

    #addressForm {
        .slidingLabel {
            max-width: 285px;
        }

        .iw-formHint {
            font-size: 12px;
        }
    }

    .iw-breadcrumb {
        font-size: 14px;

        .iw-stepNumber {
            width: 24px;
            height: 24px;
        }

        .iw-stepLine {
            height: 4px;
            width: calc(50% - 12px);
            top: calc(50% - 2px);
        }

        .iw-stepLineLeft {
            left: 0;
        }

        .iw-stepLineRight {
            right: 0;
        }

        .text-iw-BreadcrumbGrey {
            color: #dddddd !important;
        }

        .bg-iw-BreadcrumbGrey {
            background-color: #dddddd !important;
        }
    }

    .iw-basketHeadline {
        padding-top: 18px;
        font-size: 30px;
    }

    .iw-basketContainer {

        .iw-basketItem {
            padding-bottom: 30px;
            padding-top: 30px;
            border-bottom: 1px solid rgb(222, 226, 230);

            &:nth-last-child(1) {
                border-bottom: none;
            }

            .iw-imageContainer {
                .iw-productImage {
                    max-height: 190px;
                }
            }

            .iw-productInformation {
                margin-left: 39px;
                font-size: 16px;

                .iw-productInformation {
                    min-width: 185px;
                }

                div {
                    .iw-productName {
                        font-size: 20px;
                        margin-bottom: 10px;
                        color: #000000;
                    }

                    .iw-productText {
                        margin-bottom: 0;
                    }

                    .iw-productFor {
                        margin-top: 15px;
                    }
                }

                .iw-productPrice {
                    font-size: 20px;
                    color: #000000;
                }
            }

            .iw-productActionContainer {
                margin-top: 15px;

                .iw-quantityAction {
                    font-size: 16px;

                    .iw-quantityField {
                        border: none;
                        border-bottom: solid 1px #000000;
                        font-size: 14px;
                        width: 60px;
                    }
                }

                input.iw-removeButton {
                    border: none;
                    margin-top: 40px;
                    margin-left: 28px;
                    text-decoration: underline;
                    background-color: rgba(0, 0, 0, 0);
                }
            }
        }
    }

    .iw-priceContainer {
        width: 380px;
        padding-top: 30px;

        .iw-priceItem {
            border-top: 1px solid #DDDDDD;
            padding: 5px 5px 5px 8px;

            &:nth-child(1) {
                border-top: none;
            }
        }

        .iw-priceTotal {
            padding: 19px 0 0 10px;
            margin-top: 20px;
            border-top: 5px solid #000000;
            font-weight: bold;
            font-size: 22px;
            color: #000000;
        }
    }

    .iw-buttonContainer {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .iw-shippingContainer {
        font-size: 14px;
        color: #AAAAAA;
    }

    .iw-greyedOutText {
        color: #CCCCCC;
        font-size: 22px;
        margin: 30px 0;
    }

    .iw-submitPaymentContainer {
        display: none;
    }

    .iw-paymentAdapterContainer {
        width: 18px;
        height: 18px;
    }

    #loadingIndicator {
        display: none;
    }

    .iw-customDataContainer {
        .iw-addressInformationContainer {
            width: 100%;
            max-width: 285px;
        }
    }

    .iw-paymentInformationContainer {
        .custom-control-label {
            width: calc(100% - 24px);
            margin-left: 24px;
        }

        .iw-selectedPaymentImage {
            object,
            img {
                height: 20px;
            }
        }
    }
}

#footer1 {
    background-color: rgb(73, 71, 69);
    font-size: 12px;
}

#footer2 {
    background-color: rgb(39, 36, 34);
    font-size: 14px;
}

#footer1,
#footer2 {
    color: #cfcbca;

    .iw-socialMediaIcon {
        font-size: 25px;

        &:hover:after {
            transform-origin: left center;
            transform: scale(1, 1);
        }

        &:after {
            content: '';
            display: block;
            width: calc(100%);
            height: 1px;
            bottom: 5px;
            position: relative;
            background-color: #df0000ff;
            transform-origin: right center;
            transform: scale(0, 1);
            transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85) 0s;
        }
    }

    .iw-externalLink {
        color: #df0000ff;
    }

    .iw-imageBox {
        width: 82px;
        height: 50px;
        border: 1px solid;
        padding: 8px;
        margin-top: 5px;
        margin-right: 16px;
    }

    a {
        color: #cfcbca;
    }

    .iw-footerHeadline {
        font-size: 18px;
    }

    .iw-footerText {
        font-size: 14px;
    }
}

@media (max-width: 1199px) {
    #content {
        .iw-showCategory {
            .iw-productListContainer {
                &:nth-child(3n+3) .iw-productContainer {
                    margin: 0 1rem 1rem 0;
                }
                &:nth-child(2n+2) .iw-productContainer {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    #content {
        .iw-termsAndConditions {
            margin-left: 1.5rem !important;
        }

        .iw-productPage {
            .iw-imageContainer {
                width: 100%;
            }

            .iw-productInformationContainer {
                width: 100%;
            }
        }

        .iw-showCategory {
            .iw-productListContainer {
                &:nth-child(2n+2) .iw-productContainer {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #content {
        #addressForm {
            .slidingLabel {
                max-width: 100% !important;
            }
        }

        .iw-showCategory {
            .iw-productListContainer {
                .iw-productContainer {
                    margin-right: 0 !important;
                }
            }
        }

        .iw-activateRowCaption {
            width: 100% !important;
        }

        .iw-basketContainer {

            .iw-basketItem {
                flex-wrap: wrap;

                .iw-imageContainer {
                    padding-bottom: 10px;
                    text-align: center;
                    width: 100%;
                }

                .iw-productInformation {
                    margin: auto !important;

                    .iw-productPrice {
                        font-size: 16px;
                    }

                    div {
                        .iw-productName {
                            font-size: 16px;
                        }
                    }
                }

                .iw-productActionContainer {
                    width: 100%;

                    .iw-removeButton {
                        right: 10px;
                        position: absolute;
                    }
                }
            }
        }

        .iw-shippingContainer {
            width: 100% !important;
        }

        .iw-customDataContainer {
            .iw-addressContainer {
                margin-bottom: 20px;
                width: 100% !important;

                .iw-addressInformationContainer {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    #content {
        .iw-categories {
            margin-right: 0;
        }

        .iw-priceContainer {
            width: 100%;

            .iw-priceTotal {
                span {
                    width: 100%;
                }
            }
        }
    }
}

@font-face {
    font-family: 'Price';
    src: url(../Fonts/MMPrice.woff2) format('woff2');
}

.price,
.priceDecimal {
    text-shadow: transparent 0 0 1em,
        rgb(255 255 255) 0px -0.04em 0px,
        rgb(255 255 255) 0.04em 0px 0px,
        rgb(255 255 255) 0px 0.04em 0px,
        rgb(255 255 255) -0.034em -0.024em 0px,
        rgb(255 255 255) -0.03em -0.04em 0px,
        rgb(255 255 255) -0.01em -0.04em 0px,
        rgb(255 255 255) 0.052em -0.04em 0px,
        rgb(255 255 255) 0.04em -0.04em 0px,
        rgb(255 255 255) 0.02em -0.04em 0px,
        rgb(255 255 255) 0.047em -0.025em 0px,
        rgb(255 255 255) -0.045em 0.016em 0px,
        rgb(255 255 255) -0.039em 0.038em 0px,
        rgb(255 255 255) -0.052em 0.04em 0px,
        rgb(255 255 255) -0.042em 0.04em 0px,
        rgb(255 255 255) 0.031em 0.028em 0px,
        rgb(255 255 255) 0.029em 0.04em 0px,
        rgb(0 0 0 / 20%) 0.05em 0px 0.05em,
        rgb(0 0 0 / 20%) 0px 0.05em 0.05em,
        rgb(0 0 0 / 20%) 0.05em 0.05em 0.05em;
    font-family: Price, FontFamily-Headline, FontFamily, Arial, sans-serif;
    font-weight: 600;
    font-style: italic;
}

.price {
    letter-spacing: 2px;
    font-size: 48px;
    line-height: 53px;
}

.priceDecimal {
    font-size: 24px;
}
